<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Recently, a mummy was found high in the Andes mountains. The
        <chemical-latex content="^{14}C" />
        decay rate was measured to be 93.3% of the initial decay rate of
        <chemical-latex content="^{14}C" />
        in living things. The half-life of
        <chemical-latex content="^{14}C" />
        is 5730 years. How long ago did the person that was mummified die?
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{t}:$"
        append-text="$\text{years}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220A4Q5',
  components: {
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
